import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import Image from 'components/Image';

import useQueryClient from 'hooks/useQueryClient';

import { getAdCssBackgroundColorProp } from 'lib/ads';
import { sendEvent } from 'lib/gtag';
import { setLocalStorage } from 'lib/localStorage';
import { topbarQueryKeys } from 'lib/queryKeys';
import { noop } from 'lib/utils';

import { getTopbarAd } from 'services/ads';

import APP from 'constants/app';
import LOCAL_STORAGE from 'constants/localStorage';

const SCROLL_THRESHOLD_TO_PULL_UP_BANNER = 50;

const toMobileImageWithHigherRes = (mobileImage) =>
  `https://i.promobit.com.br/ar/720/${mobileImage.split('/')[3]}`;

const TopbarAd = ({ gaEventCategory, onClose = noop }) => {
  const [isScrollPositionAtThreshold, setScrollPositionAtThreshold] =
    useState(true);
  const queryClient = useQueryClient();
  const {
    data: { bgcolor, description, isClosable, image, mobileImage, url },
  } = useQuery({
    queryKey: topbarQueryKeys.ads(),
    queryFn: ({ signal }) => getTopbarAd({ signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const onCloseButtonClick = (e) => {
    e.preventDefault();
    setLocalStorage(LOCAL_STORAGE.IS_EXTENSION_ACTIVE, 'true');
    queryClient.removeQueries(topbarQueryKeys.ads());
    onClose(e);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < SCROLL_THRESHOLD_TO_PULL_UP_BANNER) {
        setScrollPositionAtThreshold(true);
        return;
      }

      if (
        window.scrollY > SCROLL_THRESHOLD_TO_PULL_UP_BANNER &&
        isScrollPositionAtThreshold
      ) {
        setScrollPositionAtThreshold(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={twJoin(
        'h-11 w-full lg:h-8',
        mobileImage ? 'block' : 'hidden lg:block'
      )}
      style={{ background: getAdCssBackgroundColorProp(bgcolor) }}
    >
      <a
        className={twJoin(
          'md:0 fixed z-40 mx-auto flex w-full items-center justify-center',
          !isScrollPositionAtThreshold ? '-top-[10px]' : 0,
          isClosable ? 'max-w-[64rem]' : 'max-w-full'
        )}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => {
          sendEvent({
            action: 'topbar_banner__click',
            category: gaEventCategory,
          });
        }}
      >
        <Image
          alt={description}
          className="h-11 w-[720px] max-w-full md:object-contain lg:h-auto lg:w-auto lg:max-w-[1920px]"
          fetchpriority="high"
          height={64}
          loading={null}
          sizes={
            mobileImage
              ? '(max-width: 1023px) 0px, 1920px'
              : '(max-width: 1280px) 1px, 1920px'
          }
          src={mobileImage || image}
          srcSet={
            mobileImage
              ? `${toMobileImageWithHigherRes(
                  mobileImage
                )} 428w, ${image} 1920w`
              : `${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w, ${image} 1920w`
          }
          width={375}
          priority
        />
        {isClosable && (
          <Button
            className="absolute right-0"
            icon={<Icon name="close" />}
            size="size4"
            type="neutral-ghost"
            onClick={onCloseButtonClick}
          />
        )}
      </a>
    </div>
  );
};

TopbarAd.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default TopbarAd;
