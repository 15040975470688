const INVALID_CATEGORY_SLUGS = ['bebidas'];

export const getAdCssBackgroundColorProp = (
  [bgColor1, bgColor2] = [null, null]
) =>
  !bgColor2
    ? bgColor1
    : `linear-gradient(45deg, ${bgColor1} 0% 50%, ${bgColor2} 50% 100%)`;

export const isOfferCategorySlugValidToActivateGoogleAd = (slug) =>
  !INVALID_CATEGORY_SLUGS.some((invalidSlug) => invalidSlug === slug);
